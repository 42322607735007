import React, { useEffect } from "react";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import Loadable from "react-loadable";
import LoaderComponent from "../../components/LoaderComponent";
import { loadTextsByNamespace } from "../../../server/helper/ssrHelper";
import config from "../../config/main.config";
import { jumpToTop } from "../../helper/util";

const EditHTMLComponent = Loadable({
	loader: () =>
		import(
			/* webpackChunkName: 'EditHTMLComponent' */ "../../components/editHTML/EditHTMLComponent"
		),
	loading: () => LoaderComponent,
});

const EditTextComponent = Loadable({
	loader: () =>
		import(
			/* webpackChunkName: 'EditTextComponent' */ "../../components/editText/EditTextComponent"
		),
	loading: () => LoaderComponent,
});

const CalendulaStiftung = () => {
	useEffect(() => {
		if (typeof window !== "undefined") {
			jumpToTop(window);
		}
	}, []);
	const namespace = "calendula-stiftung";

	return (
		<div className={"grid ui"}>
			<div
				className={"row top-image-cut"}
				style={{
					backgroundImage: `url(${config.BASE_URL_IMAGES}StiftungTopImage.jpg)`,
					height: "600px"
				}}
			>
				<img
					src={"/images/bows/Bogen6_Beige.svg"}
					alt={"bogen"}
					className={"bow-6-position"}
				/>
			</div>
			<div className={"centered row page-content-wrapper"}>
				<div className={"sixteen wide column"}>
					<div style={{ display: "none" }}>
						<BreadCrumbs
							breadCrumbData={[
								{ title: "Calendula Stiftung", url: "/calendula-stiftung" },
							]}
						/>
					</div>
				</div>
				<div className={"sixteen wide column"}>
					<h1>
						<EditTextComponent
							id={`${namespace}-header-1`}
							namespace={namespace}
						/>
					</h1>
					<EditHTMLComponent id={`${namespace}-text-1`} namespace={namespace} />
				</div>
			</div>
		</div>
	);
};

CalendulaStiftung.fetchData = () => {
	return loadTextsByNamespace(config.BASE_URL, "calendula-stiftung").then(
		(text) => {
			return text;
		},
	);
};

export default CalendulaStiftung;
